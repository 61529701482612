import { LoaderFunction } from '@remix-run/node';
import { Form, redirect, useNavigation } from '@remix-run/react';
import { format } from 'date-fns/format';
import { LoaderCircleIcon } from 'lucide-react';
import { Button } from '~/components/ui/button';
import AppLayout from '~/layouts/AppLayout';
import { BackdropLayout } from '~/layouts/BackdropLayout';
import { authenticator } from '~/models/auth.server';

export const loader: LoaderFunction = async ({ request }) => {
  const auth = await authenticator.isAuthenticated(request);

  if (auth && auth.id) {
    return redirect('/');
  }

  return null;
};

// app/routes/login.tsx
export default function Login() {
  const loading = useNavigation().state !== 'idle';
  return (
    <AppLayout today={format(new Date(), 'MMMM d, yyyy')} user={null}>
      <BackdropLayout to="back()">
        <div className="flex flex-grow flex-col items-center justify-start space-y-6">
          <h1 className="mb-4 font-headline text-2xl">Sign in or join waitlist</h1>
          <Form action="/auth/google" method="post">
            <Button variant="outline" className="flex gap-2 rounded-full px-3 transition-all">
              {loading ? (
                <LoaderCircleIcon size={15} className="animate-spin" />
              ) : (
                <img src="/google-logo.svg" alt="Google logo" width="15" />
              )}
              <span>Continue with Google</span>
            </Button>
          </Form>
          <p className="text-sm text-gray-600">
            By signing in with Google, you will be automatically added to the waitlist.
          </p>
        </div>
      </BackdropLayout>
    </AppLayout>
  );
}
